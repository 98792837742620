<template>
  <div>
    <h2>Clutches</h2>

    <b-table :items="updated_data" :fields="fields" sort-by="type" hover striped dark>
      <template #cell(wins)="field">
        <div>
          <strong>{{ field.item.wins }}</strong>
          <span class="text-muted small ml-1">{{ field.item.wins_percentage }}%</span>
        </div>
      </template>
      <template #cell(loses)="field">
        <div>
          <strong>{{ field.item.loses }}</strong>
          <span class="text-muted small ml-1">{{ field.item.loses_percentage }}%</span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },

  data() {
    return {
      fields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'wins', label: 'Won', sortable: true },
        { key: 'loses', label: 'Lost', sortable: true },
        { key: 'rounds', label: 'Rounds', sortable: true },
      ],
    }
  },

  computed: {
    updated_data() {
      return this.data && this.data.length
        ? this.data
        : [
            {
              type: '1v1',
              wins: 0,
              wins_percentage: 0,
              loses: 0,
              loses_percentage: 0,
              rounds: 0,
            },
            {
              type: '1v2',
              wins: 0,
              wins_percentage: 0,
              loses: 0,
              loses_percentage: 0,
              rounds: 0,
            },
            {
              type: '1v3',
              wins: 0,
              wins_percentage: 0,
              loses: 0,
              loses_percentage: 0,
              rounds: 0,
            },
            {
              type: '1v4',
              wins: 0,
              wins_percentage: 0,
              loses: 0,
              loses_percentage: 0,
              rounds: 0,
            },
            {
              type: '1v5',
              wins: 0,
              wins_percentage: 0,
              loses: 0,
              loses_percentage: 0,
              rounds: 0,
            },
          ]
    },
  },
}
</script>
