<template>
  <div>
    <h2>Weapon Stats</h2>

    <b-table :items="updated_data" :fields="fields" sort-by="picks" :sort-desc="true" hover striped dark>
      <template #thead-top>
        <b-tr>
          <b-th :colspan="show_pick_percentage ? 4 : 3"> Weapon Picks </b-th>
          <b-th colspan="2"> Kill Breakdown </b-th>
        </b-tr>
      </template>

      <template #cell(weapon)="field">
        <div>
          <img :src="field.item.weapon_image_url" :alt="field.item.weapon_name" />
          {{ field.item.weapon_name }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: Array,
    show_pick_percentage: Boolean,
  },

  data() {
    return {
      fields_default: [
        { key: 'weapon', label: 'Weapon', sortable: true },
        { key: 'picks', label: 'Picks', sortable: true },
        { key: 'rounds_played', label: 'Rounds Played', sortable: true },
        { key: 'pick_percentage', label: 'Pick Percentage', sortable: true },
        { key: 'kills', label: 'Kills', sortable: true },
      ],
    }
  },

  computed: {
    ...mapGetters({
      weapons: 'static/weapons',
    }),

    fields() {
      return this.fields_default.filter(field => this.show_pick_percentage || field.key !== 'pick_percentage')
    },

    updated_data() {
      return this.data && this.data.length
        ? this.data
        : Object.keys(this.weapons)
            .filter(w => w.split('-').length === 1)
            .map(weapon_name => ({
              weapon_name,
              weapon_image_url: `/images/weapons-white/${weapon_name.toLowerCase()}.png`,
              picks: 0,
              kills: 0,
              rounds_played: 0,
              pick_percentage: 0,
            }))
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 50px;
  margin-right: 0.25rem;
}
</style>
