<template>
  <div>
    <h2>Agents Stats</h2>

    <b-table :items="updated_data" :fields="fields" hover striped dark>
      <template #cell(agent)="field">
        <div class="d-flex align-items-end">
          <img :src="field.item.agent.icon" :alt="field.item.agent.name" />
          <h5 class="ml-2">
            {{ field.item.agent.name }}
          </h5>
        </div>
      </template>
      <template #cell(wins)="field">
        <div>
          <strong>{{ field.item.wins }}</strong>
          <span class="text-muted small ml-1">{{ field.item.wins_percentage }}%</span>
        </div>
      </template>
      <template #cell(loses)="field">
        <div>
          <strong>{{ field.item.loses }}</strong>
          <span class="text-muted small ml-1">{{ field.item.loses_percentage }}%</span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },

  data() {
    return {
      fields: [
        { key: 'agent', label: 'Agent', sortable: true },
        { key: 'wins', label: 'Wins', sortable: true },
        { key: 'loses', label: 'Loses', sortable: true },
      ],
    }
  },

  computed: {
    updated_data() {
      return this.data && this.data.length
        ? this.data
        : [
            {
              agent: {
                name: '',
                icon: '/images/valorant-logo.png',
              },
              wins: 0,
              wins_percentage: 0,
              loses: 0,
              loses_percentage: 0,
            },
          ]
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 50px;
  margin-right: 0.25rem;
}
</style>
