<template>
  <div>
    <h2>Aim Stats</h2>

    <b-table :items="updated_data" :fields="fields" hover striped dark>
      <template #cell(count)="field">
        <div>
          <strong>{{ field.item.count }}</strong>
          <span class="text-muted small ml-1">{{ field.item.stat_percentage }}%</span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },

  data() {
    return {
      fields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'count', label: 'Count', sortable: true },
      ],
    }
  },

  computed: {
    updated_data() {
      return this.data && this.data.length
        ? this.data
        : [
            {
              type: 'headshots',
              count: 0,
              stat_percentage: 0,
            },
            {
              type: 'bodyshots',
              count: 0,
              stat_percentage: 0,
            },
            {
              type: 'legshots',
              count: 0,
              stat_percentage: 0,
            },
          ]
    },
  },
}
</script>
