<template>
  <div>
    <div v-if="loading_stats" class="flex-fill d-flex justify-content-center mt-4 mb-4">Loading...</div>

    <div v-else>
      <!-- <ChartPlantPerformance
          class="mb-5"
          :plant_performance="plant_performance"
          :filter_plants="filter_plants"
          :filter_scenarios="filter_scenarios"
        /> -->

      <!-- TODO need api update from Mori -->
      <!-- <TableRoundPerformance
          class="mb-5"
          :data="null"
        /> -->

      <!-- <TableWinConditions
          class="mb-5"
          :data="win_conditions"
        /> -->

      <TableGeneralStats class="mb-5" :data="general_stats" />

      <TableAgentsStats class="mb-5" :data="agents_stats" />

      <TableAimStats class="mb-5" :data="aim_stats" />

      <TablePerRoleStats class="mb-5" :data="per_role_stats" />

      <TableWeaponStats class="mb-5" :data="weapon_stats" :show_pick_percentage="true" />

      <TableClutches class="mb-5" :data="clutches" />

      <!-- <h3>DATA DIFFERENT FROM TEAM RESPONSE:</h3>
        <pre>{{ data_different_from_team }}</pre> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// import TableWinConditions from '@/components/Table/WinConditions'
import TableAgentsStats from '@/components/Table/AgentsStats.vue'
import TableAimStats from '@/components/Table/AimStats.vue'
import TableClutches from '@/components/Table/Clutches.vue'
import TableGeneralStats from '@/components/Table/GeneralStats.vue'
import TablePerRoleStats from '@/components/Table/PerRoleStats.vue'
import TableWeaponStats from '@/components/Table/WeaponStats.vue'
// import TableRoundPerformance from '@/components/Table/RoundPerformance'
// import ChartPlantPerformance from '@/components/Chart/PlantPerformance'

export default {
  components: {
    // TableWinConditions,
    TableWeaponStats,
    TableGeneralStats,
    TableAgentsStats,
    TableAimStats,
    TablePerRoleStats,
    TableClutches,
    // TableRoundPerformance,
    // ChartPlantPerformance,
  },

  mounted() {
    this.$store.dispatch('player/get_maps_stats', this.$route.params)
  },

  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('player/get_maps_stats', to.params)
    next()
  },

  computed: {
    ...mapGetters({
      // plant_performance: 'player/plant_performance',
      has_map_data: 'player/has_map_data',
      loading_stats: 'player/loading_stats',
      // selected_map_id: 'player/selected_map_id',
      weapon_stats: 'player/weapon_stats',

      general_stats: 'player/general_stats',
      agents_stats: 'player/agents_stats',
      aim_stats: 'player/aim_stats',
      per_role_stats: 'player/per_role_stats',
      clutches: 'player/clutches',

      // win_conditions: 'player/win_conditions',
      // filter_plants: 'player/plant_performance_filter_plants',
      // filter_scenarios: 'player/plant_performance_filter_scenarios',

      data_different_from_team: 'player/data_different_from_team', // REMOVE
    }),
  },
}
</script>

<style></style>
