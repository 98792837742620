<template>
  <div>
    <h2>General Stats</h2>

    <b-table :items="data" :fields="fields" hover striped dark />
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },

  data() {
    return {
      fields: [
        { key: 'kills', label: 'Kills', sortable: true },
        { key: 'deaths', label: 'Deaths', sortable: true },
        { key: 'assists', label: 'Assists', sortable: true },
        { key: 'total_games', label: 'Total Games', sortable: true },
      ],
    }
  },
}
</script>
