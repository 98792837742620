<template>
  <div>
    <h2>Per Role Stats</h2>

    <b-table :items="updated_data" :fields="fields" hover striped dark />
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },

  data() {
    return {
      fields: [
        { key: 'role', label: 'Role', sortable: true },
        { key: 'adr', label: 'Average Round Damage', sortable: true },
        { key: 'acs', label: 'Average Round Score' },
        { key: 'fk', label: 'First Kills', sortable: true },
      ],
    }
  },

  computed: {
    updated_data() {
      return this.data && this.data.length
        ? this.data
        : [
            {
              role: 'def',
              adr: 0,
              acs: 0,
              fk: 0,
            },
            {
              role: 'atk',
              adr: 0,
              acs: 0,
              fk: 0,
            },
          ]
    },
  },
}
</script>
